import React from "react";
import VitalIcon from "../assets/vital.svg";
import "./ResponsivenessForAll.scss";
const FirstSection = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="container">
        <div
          className="d-flex flex-column align-items-center boxStyle"
          style={{ gap: "24px" }}
        >
          <img src={VitalIcon} alt="icon" width={"64px"} />
          <div className="d-flex flex-column" style={{ gap: "12px",  textAlign: "center" }}>
            <span style={{ color: "#3A3737", fontSize: "18px", fontWeight: 600 }}>
              NGOs in Kosovo are vital to their communities, yet face significant
              hurdles in securing sustainable funding
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "32px",
              }}
            >
              <span style={{ color: "#4158CF" }}>KAPorg empowers</span> these
              organizations by{" "}
              <span style={{ color: "#4158CF" }}>connecting</span> them with
              donors <span>and providing the tools tools thrive.</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
