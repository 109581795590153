import React from "react";
import Partners from "../assets/partners.svg";
import { Button } from "antd";

import AssessmentIcon from "../assets/assessment-icon.svg";
import PerformanceIcon from "../assets/performance-icon.svg";
import ScaleIcon from "../assets/scale-icon.svg";
import PresenceIcon from "../assets/presence-icon.svg";
import ScoresIcon from "../assets/scores-icon.svg";
import AnalysisIcon from "../assets/analysis-icon.svg";

const Development = () => {
   const spanStyle = {
      fontSize: "16px",
      color: "#3A3737"
   };

   return (
      <div className="d-md-flex optimizingContainer-kosovo" style={{ gap: "74px" }}>
         <div className="container">
            <div className="row">
               <div className="col-md-4">
                  <div
                     className="d-flex flex-column"
                     style={{ gap: "16px", width: "100%" }}
                     // use @media queries to make width 100% in mobile
                  >
                     <span
                        style={{
                           fontSize: "32px",
                           color: "#003DAA",
                           fontWeight: 600
                        }}
                     >
                        Optimizing Local Partner Performance Through In-Depth Assessments and
                        Capacity Development
                     </span>
                     <div className="d-flex flex-column partnersContainer">
                        <span style={spanStyle}>
                           Achieve Donor Readiness, Compliance, and Expanded Partnerships.
                        </span>
                     </div>
                  </div>
               </div>
               <div className="col-md-8">
                  <div className="gridContainer">
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={AssessmentIcon} alt="organizational assessments" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Develop Comprehensive Organizational Assessments
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Assess Organizational Readiness for Donor Compliance and Long-Term
                              Sustainability
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={PresenceIcon} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Build a Strong Online Presence with a Few Clicks
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Enhance your organization's global visibility and expand partnership
                              opportunities.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={AnalysisIcon} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Conduct a comprehensive performance analysis to identify key areas for
                              improvement.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Utilize validated assessment tools to conduct a thorough
                              organizational evaluation and identify core competencies and areas for
                              development.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={ScaleIcon} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Forge Strategic Partnerships on a Local and Global Scale
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Identify and Cultivate Strategic Partnerships Aligned with
                              Organizational Strengths
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={PerformanceIcon} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Monitor organizational performance and growth through real-time data
                              analytics.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Accelerate Organizational Growth with Personalized Support,
                              Data-Driven Insights and Personalized Improvement Plans. Monitor
                              organizational performance and growth through real-time data
                              visualization.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={ScoresIcon} alt="" />
                        <div className="d -flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Enhance organizational credibility through independent validation of
                              assessment scores by KAPorg.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Benefit from KAPorg's independent organizational assessments for
                              unbiased insights and improvement recommendations.
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default Development;
