import React from "react";
import Partners from "../assets/secondBanner.jpg";
import { Button } from "antd";
import "./ResponsivenessForAll.scss";
import blueTick from '../assets/blueTickk.svg'

const SocialChange = () => {
  const spanStyle = {
    fontSize: "16px",
    color: "#3A3737",
  };

  return (
    <div
      className="d-md-flex align-items-center mainContainer"
      style={{ gap: "80px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="imageContainer-kosovo KosovoImageContainer">
              <div className="imageOverlay"></div>
              <img src={Partners} alt="" width={"100%"} />
            </div>
          </div>
          <div className="col-md-6" style={{display: 'flex', alignItems: 'center'}}>
            <div
              className="d-flex flex-column socialChangeContainer-kosovo"
              style={{ gap: "16px" }}
              // use @media queries to make width 100% in mobile
            >
              <span
                style={{
                  fontSize: "32px",
                  color: "#003DAA",
                  fontWeight: 600,
                }}
              >
                KAPorg is your partner in driving social change
              </span>
              <div className="d-flex flex-column" style={{ gap: "20px" }}>
                <span style={spanStyle}>
                  KAPorg is a groundbreaking platform accelerating localization
                  efforts in humanitarian aid and development by uniting key
                  stakeholders and empowering organizations to accomplish their
                  missions.
                </span>
                <ul>
                  <li><img src={blueTick} alt="blueTick" />Streamline localization efforts</li>
                  <li><img src={blueTick} alt="blueTick" />Expand your partner network</li>
                  <li><img src={blueTick} alt="blueTick" />Strengthen organizational capacity</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  );
};
export default SocialChange;
