import React from "react";
import MapImg from "../assets/map.svg";

const SecuringFunds = () => {
  const spanStyle = {
    fontSize: "16px",
    color: "#3A3737",
  };

  return (
    <div
      className="d-md-flex align-items-center"
      style={{ backgroundColor: "#DFE6FF" }}
    >
      <div className="container" style={{padding: '50px 0'}}>
        <div className="row" style={{alignItems: 'center'}}>
          <div className="col-md-6">
            <div
              className="d-flex flex-column fundsContainer"
              style={{ gap: "16px" }}
              // use @media queries to make width 100% in mobile
            >
              <span
                style={{
                  fontSize: "32px",
                  color: "#003DAA",
                  fontWeight: 600,
                }}
              >
                Securing Funds is the Main Challenge for NGOs in Kosovo
              </span>
              <div className="d-flex flex-column" style={{ gap: "16px" }}>
                <span style={spanStyle}>
                  There are nearly 200 non-governmental organizations in Kosovo.
                  However, these organizations face a significant challenge due to a
                  lack of financial resources to effectively carry out their
                  activities. The lack of financial support poses a significant
                  challenge for these organizations to fulfill their mission.
                </span>
                <span style={spanStyle}>
                  <strong>
                    Many NGOs rely heavily on short-term grants, making it difficult
                    to plan and implement long-term projects.
                  </strong>{" "}
                  This reliance on unpredictable funding sources creates instability
                  and hampers the ability of NGOs to address the root causes of the
                  crisis.
                </span>
                <span style={spanStyle}>
                  As one NGO representative stated,{" "}
                  <strong>
                    “NGO-s in Kosovo are mostly dependent on donors, this dependence
                    on donor's assistance, has resulted in a gap creation between
                    their activities and the need of their constituencies”.
                  </strong>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="imageContainer-kosovo"
              // style={{ width: "40%", padding: "20px 100px 20px 0" }}
            >
              <img src={MapImg} alt="" height={"537px"} width={"100%"} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default SecuringFunds;
