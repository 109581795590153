import React from "react";
import PeopleImage from "../assets/thirdBanner.jpg";

const Resources = () => {
  const spanStyle = {
    fontSize: "16px",
    color: "#3A3737",
  };

  return (
    <div
      className="d-md-flex align-items-center platformContainer-kosovo"
      style={{
        backgroundColor: "#DFE6FF",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div
              className="d-flex flex-column"
              style={{ gap: "32px" }}
              // use @media queries to make width 100% in mobile
            >
              <div className="d-flex flex-column">
                <span
                  style={{
                    fontSize: "22px",
                    color: "#003DAA",
                    fontWeight: 600,
                  }}
                >
                  Our platform provides
                </span>
                <span
                  style={{
                    fontSize: "32px",
                    color: "#003DAA",
                    fontWeight: 600,
                  }}
                >
                  tools and resources to help Kosovo NGOs
                </span>
              </div>

              <div className="d-flex flex-column">
                <ul
                  className="d-flex flex-column"
                  style={{
                    padding: "0 0 0 20px",
                    gap: "24px",
                  }}
                >
                  <li style={{ letterSpacing: "0.32px" }}>
                    Identify and secure funding opportunities
                  </li>
                  <li style={{ letterSpacing: "0.32px" }}>
                    Upgrade organizational management and operations
                  </li>
                  <li style={{ letterSpacing: "0.32px" }}>
                    Improve project design, implementation, and evaluation
                  </li>
                  <li style={{ letterSpacing: "0.32px" }}>
                    Build strong partnerships with donors and other stakeholders
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="imageAbsolute-kosovo">
              <img src={PeopleImage} alt="" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Resources;
